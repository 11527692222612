<app-banner></app-banner>
<p style="text-align: center;">Više o projektu možete saznati ovdje: <a href="https://esleepdhealth.nethub.hr/about/">https://esleepdhealth.nethub.hr/about/</a></p>

<div class=" subjects">
    <mat-card class="card" *ngFor="let subject of subjects">
            <img mat-card-image class="card-image" [src]="  subject.image" alt="">
                <h3 class="card-title">{{  subject.name}}</h3>
                <p style="margin-bottom: 4px;"></p>
                <p>{{  subject.description}}</p>
                <mat-divider></mat-divider>
                <div style="display: grid; grid-template-columns: auto 1fr; padding-top: 12px;">
                    <mat-icon>people</mat-icon>
                    <p style="margin-left: 8px; font-weight: 500">{{ subject.participantsIds.length}} Sudionika</p>
                    <mat-icon>description</mat-icon>
                    <p style="margin-left: 8px; font-weight: 500">{{ subject.assetsIds.length}} Materijala</p>
                    <mat-icon>event</mat-icon>
                    <p style="margin-left: 8px; font-weight: 500">{{ subject.EventsIds.length}} Događanja</p>
                </div>
                <mat-divider></mat-divider>
            <div class="card-buttons">
                <button mat-raised-button color="accent" (click)="details(subject)"><mat-icon>history_edu</mat-icon> DETALJI</button>
                <button class="short-card-button" *ngIf="($auth.participant | async)!.role == 'admin' || ($auth.participant | async)!.role == 'organizer'" (click)="edit(  subject)" mat-raised-button color="accent"><mat-icon>edit</mat-icon></button>
                <button class="short-card-button" *ngIf="($auth.participant | async)!.role == 'admin' || ($auth.participant | async)!.role == 'organizer'" (click)="delete(  subject)" mat-raised-button color="accent"><mat-icon>delete</mat-icon></button>
            </div>
    </mat-card>
    <button *ngIf="($auth.participant | async)!.role == 'admin' || ($auth.participant | async)!.role == 'organizer'" mat-raised-button color="accent" style="height: 72px; width: 72px;" (click)="create()"><mat-icon style="scale: 3;">add</mat-icon></button>
</div>

<div class="banner-container" *ngIf="($auth.selectedProject | async) && ($auth.selectedProject | async).id == 'avGKV8FFogtT6JfuOHuu'">
    <mat-card class="health_banner" >

    </mat-card >
    <mat-card class="health_banner_2" >

    </mat-card >
</div>
